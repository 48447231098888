<template>
  <div>
    <!--Stats cards-->
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6" v-for="stats in statsCards">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="stats.value">
          <div class="stats" slot="footer">
            <i :class="stats.footerIcon"></i>
            {{stats.footerText}}
          </div>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-sm-6">
        <card>
          <template slot="header">
            <h4 class="card-title">玩家总数量</h4>
            <p class="category">当日总人次</p>
          </template>
          <line-chart :labels="stockChart.labels"
                      :height="250"
                      :color="'#00aaff'"
                      :extra-options="stockChart.options"
                      :datasets="chart_playerNum_all">
          </line-chart>
        </card>
      </div>
      <div class="col-lg-4 col-sm-6">
        <card>
          <template slot="header">
            <h4 class="card-title">人均视频次数</h4>
            <p class="category">总视频次数/总人次</p>
          </template>
          <line-chart :labels="stockChart.labels"
                      :height="250"
                      :color="'#55ff00'"
                      :extra-options="stockChart.options"
                      :datasets="chart_videoNum_avg">
          </line-chart>
        </card>
      </div>
      <div class="col-lg-4 col-sm-6">
        <card>
          <template slot="header">
            <h4 class="card-title">加载成功率</h4>
            <p class="category">加载成功的比例</p>
          </template>
          <line-chart :labels="stockChart.labels"
                      :height="250"
                      :color="'#aaffff'"
                      :extra-options="stockChart.options"
                      :datasets="chart_loadRatio">
          </line-chart>
        </card>
      </div>
      <div class="col-lg-4 col-sm-6">
        <card>
          <template slot="header">
            <h4 class="card-title">视频人数占比</h4>
            <p class="category">看视频的人占总人群的多少</p>
          </template>
          <line-chart :labels="stockChart.labels"
                      :height="250"
                      :color="'#f17e5d'"
                      :extra-options="stockChart.options"
                      :datasets="chart_videoRatio">
          </line-chart>
        </card>
      </div>
    </div>
    </div>

  </div>
</template>
<script>
  import CircleChartCard from 'src/components/UIComponents/Cards/CircleChartCard.vue'
  import LineChart from 'src/components/UIComponents/Charts/LineChart'
  import ChartCard from 'src/components/UIComponents/Cards/ChartCard';
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard';
  import {Badge} from 'src/components/UIComponents'
  import Loading from 'src/components/Dashboard/Layout/LoadingMainPanel.vue'
  import TaskList from './Widgets/TaskList'
  const WorldMap = () => ({
    component: import('./../Maps/WorldMap.vue'),
    loading: Loading,
    delay: 200
  })
  const tooltipOptions = {
    tooltipFillColor: "rgba(0,0,0,0.5)",
    tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipFontSize: 14,
    tooltipFontStyle: "normal",
    tooltipFontColor: "#fff",
    tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipTitleFontSize: 14,
    tooltipTitleFontStyle: "bold",
    tooltipTitleFontColor: "#fff",
    tooltipYPadding: 6,
    tooltipXPadding: 6,
    tooltipCaretSize: 8,
    tooltipCornerRadius: 6,
    tooltipXOffset: 10,
  };

  export default {
    components: {
      StatsCard,
      ChartCard,
      CircleChartCard,
      WorldMap,
      LineChart,
      Badge,
      TaskList
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    data () {
      return {
        statsCards: [
        ],
        stockChart: {
          labels: ["七天前","六天前", "五天前", "四天前", "三天前", "前天", "昨天"],
          color: '#f17e5d',
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                ticks: {
                  fontColor: "#9f9f9f",
                  beginAtZero: true,
                  maxTicksLimit: 4,
                },
                gridLines: {
                  drawBorder: false,
                  borderDash: [8, 5],
                  zeroLineColor: "transparent",
                  color: '#9f9f9f'
                }

              }],
              xAxes: [{
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  borderDash: [8, 5],
                  color: 'transparent',
                  zeroLineColor: "transparent"
                },
                ticks: {
                  padding: 20,
                  fontColor: "#9f9f9f"
                }
              }]
            }
          }
        },
        chart_playerNum_all: [],
        chart_videoNum_avg: [],
        chart_loadRatio: [],
        chart_videoRatio: []
      }
    },
    mounted(){
        this.stockChart.labels = [];
        let chartLabels = [];
        let time_now = new Date().getTime();
        for(let i =0;i<7;i++){
          time_now -= 24 * 60 * 60 * 1000;
          let dateTemp = new Date(time_now);
          chartLabels.push((dateTemp.getMonth()+1)+"月"+dateTemp.getDate()+"日");
        }
        chartLabels = chartLabels.reverse();
        this.stockChart.labels = chartLabels;
        this.axios.post(this.$myStore.ServerUrl+'/game/lydy/getDashboardData',{code:"qegahwcbqvztrqoixmemr"}).then((response)=>{
          if(response.data.code == 200){
            this.statsCards = [];
            this.statsCards.push({
              type: 'warning',
              icon: 'nc-icon nc-globe',
              title: '近七日总人数',
              value: 'xxx',
              footerText: 'Last week',
              footerIcon: 'nc-icon nc-calendar-60'
            });
            this.statsCards.push({
              type: 'success',
              icon: 'nc-icon nc-vector',
              title: '近七日平均视频次数',
              value: 'xxx',
              footerText: 'Last week',
              footerIcon: 'nc-icon nc-calendar-60'
            });
            this.statsCards.push({
              type: 'danger',
              icon: 'nc-icon nc-favourite-28',
              title: '近七日ccccc',
              value: 'xxx',
              footerText: 'Last week',
              footerIcon: 'nc-icon nc-calendar-60'
            });
            this.chart_playerNum_all.push({
              label: "数据",
              borderColor: "#00aaff",
              pointBackgroundColor: "#00aaff",
              pointRadius: 3,
              pointHoverRadius: 3,
              lineTension: 0,
              fill: false,
              borderWidth: 3,
              data: new Array(7).fill(0)
            });
            this.chart_videoNum_avg.push({
              label: "全用户",
              borderColor: "#3cb600",
              pointBackgroundColor: "#3cb600",
              pointRadius: 3,
              pointHoverRadius: 3,
              lineTension: 0,
              fill: false,
              borderWidth: 3,
              data: new Array(7).fill(0)
            });
            this.chart_videoNum_avg.push({
              label: "新用户",
              borderColor: "#55007f",
              pointBackgroundColor: "#55007f",
              pointRadius: 3,
              pointHoverRadius: 3,
              lineTension: 0,
              fill: false,
              borderWidth: 3,
              data: new Array(7).fill(0)
            });
            this.chart_loadRatio.push({
              label: "数据",
              borderColor: "#5555ff",
              pointBackgroundColor: "#5555ff",
              pointRadius: 3,
              pointHoverRadius: 3,
              lineTension: 0,
              fill: false,
              borderWidth: 3,
              data: new Array(7).fill(0)
            });
            this.chart_videoRatio.push({
              label: "数据",
              borderColor: "#f17e5d",
              pointBackgroundColor: "#f17e5d",
              pointRadius: 3,
              pointHoverRadius: 3,
              lineTension: 0,
              fill: false,
              borderWidth: 3,
              data: new Array(7).fill(0)
            });
            for(let i=0;i<7;i++){
              if(response.data.data.playerNum_all[i] <= 0){
                this.chart_playerNum_all[0].data[i] = 0;
                this.chart_videoNum_avg[0].data[i] =0;
                this.chart_videoNum_avg[1].data[i] =0;
                this.chart_loadRatio[0].data[i] =0;
                this.chart_videoRatio[0].data[i] =0;
              }else if(response.data.data.playerNum_loaded_new[i] <= 0){
                this.chart_playerNum_all[0].data[i] = response.data.data.playerNum_all[i];
                this.chart_videoNum_avg[0].data[i] =(response.data.data.videoNum[i] /response.data.data.playerNum_all[i]).toFixed(4);
                this.chart_videoNum_avg[1].data[i] =0;
                this.chart_loadRatio[0].data[i] =(response.data.data.playerNum_loaded[i] /response.data.data.playerNum_all[i]).toFixed(4);
                this.chart_videoRatio[0].data[i] =(response.data.data.playerNum_lookVideo[i] /response.data.data.playerNum_all[i]).toFixed(4);
              }else{
                this.chart_playerNum_all[0].data[i] = response.data.data.playerNum_all[i];
                this.chart_videoNum_avg[0].data[i] =(response.data.data.videoNum[i] /response.data.data.playerNum_all[i]).toFixed(4);
                this.chart_videoNum_avg[1].data[i] =(response.data.data.videoNum_new[i] /response.data.data.playerNum_loaded_new[i]).toFixed(4);
                this.chart_loadRatio[0].data[i] =(response.data.data.playerNum_loaded[i] /response.data.data.playerNum_all[i]).toFixed(4);
                this.chart_videoRatio[0].data[i] =(response.data.data.playerNum_lookVideo[i] /response.data.data.playerNum_all[i]).toFixed(4);
              }
            }
          }else{
            this.$notify(
              {
                message:response.data.msg,
                icon: 'nc-icon nc-app',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'danger'
              })
          }
        }).catch((response)=>{
          console.log(response);
        })
    }
  }
</script>
<style>

</style>
